<template>
  <div class="text-weight-medium text-h6 text-weight-medium">Your forms</div>
  <p class="text-grey-6">Let's create form and custom fields as you want</p>
  <q-list bordered separator class="q-mt-sm max-w-full-pt bg-white">
    <q-item clickable v-ripple v-for="form in forms" :key="form._id">
      <q-item-section class="text-subtitle2" @click="gotoDetailForm(form)">
        {{ form.title }}
      </q-item-section>
      <q-item-section side>
        <div class="q-gutter-sm">
          <q-btn padding="5px" flat color="grey-6" round icon="edit" @click="gotoDetailForm(form)" />
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <Empty v-if="!forms.length" />

  <!-- new form button -->
  <div class="q-mt-lg">
    <q-btn
      outline
      no-caps
      color="grey"
      padding="0 10px"
      icon="add"
      @click.prevent="gotoNewForm"
      label="New form"
      class="bg-white"
    />
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import FormListMixin from '../mixins/FormListMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'

@Options({
  components: { Empty },
  directives: { maska },
})
export default class FormsOnDashboard extends mixins(FormListMixin) {}
</script>
