import { render } from "./ActivityOnDashboard.vue?vue&type=template&id=0a149dd9&scoped=true"
import script from "./ActivityOnDashboard.vue?vue&type=script&lang=ts"
export * from "./ActivityOnDashboard.vue?vue&type=script&lang=ts"

import "./ActivityOnDashboard.vue?vue&type=style&index=0&id=0a149dd9&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0a149dd9"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QToolbar,QToolbarTitle,QSeparator,QCardSection,QScrollArea,QList});
