<template>
  <section class="q-pa-md row q-col-gutter-lg">
    <div class="col-12 col-md-6">
      <div class="q-mb-lg">
        <ProjectsOnDashboard />
      </div>

      <div class="q-mb-lg">
        <FormsOnDashboard />
      </div>
    </div>
    <div class="col-12 col-md-6">
      <ActivityOnDashboard :key="workspaceId" />
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'

import logging from '@/utils/logging'
import CLoading from '@/components/common/ui/CLoading.vue'
import ActivityOnDashboard from '@/components/activity/ActivityOnDashboard.vue'
import ProjectsOnDashboard from '@/components/project/ui/ProjectsOnDashboard.vue'
import FormsOnDashboard from '@/components/form/ui/FormsOnDashboard.vue'
import { Watch } from 'vue-property-decorator'

@Options({
  components: { CLoading, ActivityOnDashboard, ProjectsOnDashboard, FormsOnDashboard },
  directives: { maska },
})
export default class Dashboard extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  @Watch('workspace', { immediate: true })
  workspaceChanged() {
    if (!this.workspace?.title) {
      return
    }

    this.$meta.setMeta({ title: [this.workspace?.title] })
  }

  beforeMount() {
    logging.debugRender(Dashboard.name)
  }
}
</script>
