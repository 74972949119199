import { render } from "./FormsOnDashboard.vue?vue&type=template&id=0ed0386a"
import script from "./FormsOnDashboard.vue?vue&type=script&lang=ts"
export * from "./FormsOnDashboard.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QBtn});qInstall(script, 'directives', {Ripple});
