
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import FormListMixin from '../mixins/FormListMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'

@Options({
  components: { Empty },
  directives: { maska },
})
export default class FormsOnDashboard extends mixins(FormListMixin) {}
