<template>
  <div class="text-weight-medium text-h6 text-weight-medium">Let's speed up your work, {{ userInfo.displayName }}</div>
  <p class="text-grey-6">Create project and custom form fields then send to you co-worker</p>
  <q-list bordered separator class="q-mt-sm max-w-full-pt bg-white">
    <q-item
      clickable
      v-for="project of projects"
      :active="projectId === project._id"
      :key="project._id"
      :style="{ 'border-left': `5px solid ${project.color}` }"
    >
      <q-item-section v-ripple class="ellipsis" @click="onProjectClick(project)">
        <h3 class="text-subtitle1">
          {{ project.title }}
        </h3>
        <p class="text-grey-6">
          {{ project.description }}
        </p>
      </q-item-section>

      <q-item-section side v-if="project.canWrite">
        <q-btn flat icon="more_vert" padding="0" round size="md" color="white" text-color="grey-8">
          <q-menu anchor="bottom left" self="center left">
            <q-list dense>
              <q-item clickable v-close-popup @click="onEditProject(project)">
                <q-item-section> Edit project </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-item-section>
    </q-item>
  </q-list>

  <Empty v-if="!projects.length" />

  <!-- new project button -->
  <div class="q-mt-lg">
    <q-btn
      outline
      no-caps
      color="grey"
      class="bg-white"
      padding="0 10px"
      icon="add"
      @click="modalVisibleProjectForm = true"
      label="New project"
    />
  </div>

  <ProjectFormModal
    v-if="modalVisibleProjectForm"
    :modalVisible="modalVisibleProjectForm"
    :projectData="selectedProject"
    @update:closeModal="onCloseModalProjectForm"
  />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { UserModel } from '@/components/user/user-model'

import ProjectMixin from '../mixins/ProjectMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'
import ProjectFormModal from '../ProjectFormModal.vue'

@Options({
  components: {
    Empty,
    ProjectFormModal,
  },
  directives: { maska },
})
export default class ProjectsOnDashboard extends mixins(ProjectMixin) {
  modalVisibleProjectForm = false
  selectedProject: ProjectModel | null = null

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get projectId() {
    return this.$route.params.projectId as string
  }

  set projectId(projectId: string) {
    this.$goto('workspace_board.projects', { projectId })
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get projects(): ProjectModel[] {
    return this.$store.getters.projects(this.workspaceId) || []
  }

  onCloseModalProjectForm() {
    this.modalVisibleProjectForm = false
    this.selectedProject = null
  }

  onEditProject(project: ProjectModel) {
    this.selectedProject = project
    this.modalVisibleProjectForm = true
  }

  onProjectClick(project: IProject) {
    if (!project._id) {
      return
    }

    this.projectId = project._id
  }
}
</script>
