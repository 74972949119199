
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ActivityModel } from '@/components/activity/activity-model'

import ActivityMixin from './mixins/ActivityMixin.vue'
import Empty from '../common/ui/Empty.vue'
import ActivityItem from './ui/ActivityItem.vue'
import CLoading from '../common/ui/CLoading.vue'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { CLoading, Empty, ActivityItem },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ActivityOnDashboard extends mixins(ActivityMixin) {
  loading = true

  get activities(): ActivityModel[] {
    return this.$store.getters.activities || []
  }

  get someActivities() {
    return cloneDeep(this.activities).splice(0, 15)
  }

  async created() {
    await this.getActivityList()
    this.loading = false
  }
}
