<template>
  <q-card flat class="activity-container">
    <q-toolbar>
      <q-toolbar-title> Recent activities </q-toolbar-title>
    </q-toolbar>

    <q-separator />
    <CLoading v-if="loading" />
    <q-card-section v-else class="col q-pa-none scroll activity-modal__main-content">
      <q-scroll-area class="activity-scroll-area">
        <q-list separator>
          <ActivityItem v-for="activity in someActivities" :activity="activity" :key="activity._id" />
        </q-list>
        <Empty v-if="!activities?.length" />
      </q-scroll-area>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ActivityModel } from '@/components/activity/activity-model'

import ActivityMixin from './mixins/ActivityMixin.vue'
import Empty from '../common/ui/Empty.vue'
import ActivityItem from './ui/ActivityItem.vue'
import CLoading from '../common/ui/CLoading.vue'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { CLoading, Empty, ActivityItem },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ActivityOnDashboard extends mixins(ActivityMixin) {
  loading = true

  get activities(): ActivityModel[] {
    return this.$store.getters.activities || []
  }

  get someActivities() {
    return cloneDeep(this.activities).splice(0, 15)
  }

  async created() {
    await this.getActivityList()
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.activity-scroll-area {
  height: calc(100vh - 150px);
}
.activity-container {
  margin-top: 12px;
}
</style>
