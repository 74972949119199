import { render } from "./ProjectsOnDashboard.vue?vue&type=template&id=03ae61e0"
import script from "./ProjectsOnDashboard.vue?vue&type=script&lang=ts"
export * from "./ProjectsOnDashboard.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QBtn,QMenu});qInstall(script, 'directives', {Ripple,ClosePopup});
