
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { UserModel } from '@/components/user/user-model'

import ProjectMixin from '../mixins/ProjectMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'
import ProjectFormModal from '../ProjectFormModal.vue'

@Options({
  components: {
    Empty,
    ProjectFormModal,
  },
  directives: { maska },
})
export default class ProjectsOnDashboard extends mixins(ProjectMixin) {
  modalVisibleProjectForm = false
  selectedProject: ProjectModel | null = null

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get projectId() {
    return this.$route.params.projectId as string
  }

  set projectId(projectId: string) {
    this.$goto('workspace_board.projects', { projectId })
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get projects(): ProjectModel[] {
    return this.$store.getters.projects(this.workspaceId) || []
  }

  onCloseModalProjectForm() {
    this.modalVisibleProjectForm = false
    this.selectedProject = null
  }

  onEditProject(project: ProjectModel) {
    this.selectedProject = project
    this.modalVisibleProjectForm = true
  }

  onProjectClick(project: IProject) {
    if (!project._id) {
      return
    }

    this.projectId = project._id
  }
}
